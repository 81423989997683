import type { BasePageContentAPI } from '@backmarket/http-api/src/api-specs-content/models/base-page-content'
import { BLOCKS } from '@contentful/rich-text-types'

const HOME_FALLBACK_DATA: BasePageContentAPI = {
  id: '3WflFt0AhP0k5agSAfqagd',
  name: 'home-duplicated',
  blocks: [
    {
      id: '3Wan2wOxtKkMbp4CQsTQpE',
      type: 'Carousel',
      props: {
        technicalName: 'Carousel - GREECE',
        slides: [
          {
            id: 'NW5dX6p5QIia6SkM1rBvl',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/5GKoiYlvggVmoy5LpUKNsM/41bdd35f5868b5db96633fd4a8721296/GENERIC_HPBANNERS_iPhone13_Desktop_GR.jpeg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/7oBGdm5IP7spncw6CZGMTF/f9e6f288fdad42ddf5418ea09f14d755/GENERIC_HPBANNERS_iPhone13_Mobile_GR.jpeg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'GR - iPhone 13 x Generic',
            },
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/iphone-13/14c5d644-a43a-42a8-b8f3-37d5af3936fc',
            },
          },
          {
            id: '251vgL6Wbgf3gp6Scwccou',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/61xfPfdJixKFllZuZYVM3V/da2ed1b2fda865d8a11775d0885cab65/HP_Desktop-Greek.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/3HGvWr13i0WafqC6vD7ugn/3aaea8b8460ba657f33f97bb6d0b431f/HP_Mobile-Greek.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'GR - Good Deals ',
            },
            link: {
              href: 'https://www.backmarket.gr/el-gr/e/good-deals',
            },
          },
          {
            id: '3pWosA5EumTWRAuZVkRncx',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/72mP7ySQB1jSyYmvDwdJys/82f0ba36fc6dd4d79e99400d6bf7e2d7/Copy_of_VISION_Desktop_GR.jpg',
              width: 2880,
              height: 686,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/6wBPYhweqseWfDB9cQB7fb/b36b4bc3b8186b4117bb8ffacb7e2319/Copy_of_VISION_Mobile_GR.jpg',
              widthMobile: 750,
              heightMobile: 750,
              alt: 'GR - Vision',
            },
            link: {
              href: 'https://www.backmarket.gr/el-gr/about-us',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '3crkEtdoRtYXySE9lfb7Ls',
      type: 'Categories',
      props: {
        title: 'Τα καλύτερα refurbished',
        subtitle:
          'Έχουν γκουγκλαριστεί περισσότερες φορές κι από τον Μοχάμεντ Άλι.',
        categories: [
          {
            id: '2Xw9sI9uIshWldLT0uN2Jp',
            title: 'Smartphones',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3cnra6im5fWnEyRwS71Uvg/13a3577c2accfd96a0d0cde8dfb99c0d/iphone_12.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12',
            },
            tagline:
              "Ακόμα πιο έξυπνα από τα ολοκαίνουργια (απ' ό,τι φαίνεται).",
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/smartphones/6c290010-c0c2-47a4-b68a-ac2ec2b64dca',
            },
          },
          {
            id: 'K1TzwbsFHwX967P03pMvS',
            title: 'Apple Corner',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3fEBcng8EKMrWXZNfXsXWU/f7e9700aae4c8fc548ab73b0a3d4ba94/iPhone_12.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12',
            },
            tagline: 'Πιο δυνατό από το Apple Store.',
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/apple/befd8e84-d318-48eb-88ef-362979a53183',
            },
          },
          {
            id: '5b6T3qJ1iI93e2rwvExZqe',
            title: 'MacBook',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6MZVE5kh4OulMTCQlbTuT8/4e34cf9ff41873836b05e58f39b052d7/MacBook_Pro_Touchbar.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'MacBook Pro TouchBar',
            },
            tagline: 'Απόκτησε ένα Mac σε χαμηλή τιμή',
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/ola-ta-macbook/297f69c7-b41c-40dd-aa9b-93ab067eb691',
            },
          },
          {
            id: '4YAkb7YsjNCOcTOvPmkLgM',
            title: 'Tablets',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/3q7nQi6Q5LKO60xX5RPieH/d7a7212c79637e2f4bbafbb5d7704a3a/ipad_pro_2019.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPad Pro',
            },
            tagline:
              'Μέχρι -40%. Αρκετά βοηθητικό για να κάνεις εκείνη τη συνδρομή στο Netflix.',
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/tablets/5a3cfa21-b588-49b1-b4e9-2636bec68ada',
            },
          },
          {
            id: '3Tbu1ridFk0EClbNHTN4IY',
            title: 'Apple Watch',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/2coRI1LOw46qbxuVTi3bJY/0ecd2938ae90081221aaf23fb238f885/AppleWatch6.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Apple Watch',
            },
            tagline: 'Για να βλέπεις Youtube στην πιο μικρή οθόνη.',
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/apple-watch/92caf545-c033-409f-bcb0-d3cfec8af49d',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '5dKyT3YXBtFde3mViEc0cn',
      type: 'Categories',
      props: {
        title: 'Άλλες κατηγορίες',
        subtitle:
          'Τίποτα δεν χάθηκε, όλα κερδήθηκαν, και όλα ανακατασκευάστηκαν.',
        categories: [
          {
            id: '4Vxq9DLxYs5ALNiTIbodro',
            title: 'iPhone',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/7GL9xnTTi3VasDXkNZFcY0/1fbf77311e13af1e2b8841971c332260/iphone-12.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'iPhone 12',
            },
            tagline: 'Τα πιο φρέσκα μήλα στην αγορά.',
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/iphone/aabc736a-cb66-4ac0-a3b7-0f449781ed39',
            },
          },
          {
            id: '5A4L1im3TrxtGTZfQUEBvX',
            title: 'Samsung Galaxy',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/c3phFqsPsCEl7dJiGDX5d/79671748de194ed0b0ac55ff41e35c1e/S20STORE.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Samsung s20',
            },
            tagline: 'Υπεργαλαξιακά κινητά σε μειωμένες τιμές.',
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/smartphone-samsung/99760870-ed75-482f-a626-2b4f964c55ae',
            },
          },
          {
            id: '3q4JHPC4NtqlDHa1ELf0sx',
            title: 'Ηχος',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/6UBdFdF7X6S0nGCexTB9pw/4342f8d24500ad3dc28bc0a440ef67bb/308532_7b427385-34fc-40f5-9e68-73890ed77684-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'AirPods & Earphones',
            },
            tagline: 'Η μουσική εξημερώνει τις τιμές.',
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/ekhos-akoustika-kai-ekheia/6a6b4d5a-c50d-4a61-9a7a-3c3b1ce00e50',
            },
          },
          {
            id: '7feedhWoVQxfXBipPiAM2K',
            title: 'Laptops',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/4rah4soJKbLIZaX0YrEIfQ/d71846806d1743c66367fe3aee3a4fc9/Asus_Zen_book_Pro.png',
              width: 400,
              height: 400,
              srcMobile: '',
              widthMobile: 0,
              heightMobile: 0,
              alt: 'Laptop windows',
            },
            tagline:
              'Για τις στιγμές που η οθόνη του κινητού είναι πολύ μικρή.',
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/upologistes/591cd764-0b1d-4822-8eec-7d4916239418',
            },
          },
          {
            id: '5xZbaJUmwpYnWkNSg7sDNE',
            title: 'Εικόνα, Κάμερα και Drones',
            image: {
              src: 'https://images.ctfassets.net/mmeshd7gafk1/51pMifL5BxMUDHSVwyAeBE/381582494827fb9fd4040130f9ac61c6/129798_a2cc9fc1-07fb-47cf-8613-ff82a354a3f8-thumb.jpg',
              width: 400,
              height: 400,
              srcMobile:
                'https://images.ctfassets.net/mmeshd7gafk1/5T5ucA4BO8D0FC6BUnYB8a/16c420c346dd438668e23558d7fe6669/esfNikon.jpeg',
              widthMobile: 200,
              heightMobile: 200,
              alt: 'ES- BF Nikon',
            },
            tagline:
              'Για να απαθανατίσετε τα πιο όμορφα καλοκαιρινά ηλιοβασιλέματα',
            link: {
              href: 'https://www.backmarket.gr/el-gr/l/eikona-kamera-drones/79708b0b-da5f-4302-b563-4121dbc0fedd',
            },
          },
        ],
      },
      hiddenDevices: [],
    },
    {
      id: '5d31zCPPzJs7rXJWAHEhAo',
      type: 'Faq',
      props: {
        title: 'Τι άλλο;',
        subtitle: 'Οι 4 ερωτήσεις που ακούμε πιο συχνά',
        questions: [
          {
            id: '2nNXugusVFKTLvpfAt6Ium',
            title:
              'Πώς θα ξέρω ότι η συσκευή μου δεν θα «τα φτύσει» σε έναν μήνα;',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Από τη διαδικασία ελέγχου πριν από την τελική επιλογή όλων των πωλητών έως τις «μυστικές παραγγελίες» που υποβάλλει το τεχνικό προσωπικό του Back Market (με μυστικές κωδικές ονομασίες, εννοείται), μας ενδιαφέρει να διασφαλίζουμε 110% ότι οι συσκευές που σου προσφέρουμε είναι αυτές ακριβώς που υποσχόμαστε. Επίσης, κάθε συσκευή συνοδεύεται από περιθώριο 30 ημερών σε περίπτωση που αλλάξεις γνώμη και εγγύηση 1 έτους.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '66wJ45fXVIHiZmLq2jWYjz',
            title:
              'Ποια είναι η διαφορά ανάμεσα στο ανακατασκευασμένο και στο καινούργιο;',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        "Εξωτερικά, ένα ανακατασκευασμένο smartphone φαίνεται (και λειτουργεί) σαν καινούργιο. Αυτό όμως που έχει σημασία είναι ό,τι υπάρχει στο εσωτερικό του. Οι ανακατασκευασμένες συσκευές –smartphone, tablet, laptop και υπολογιστές– έχουν πολύ λιγότερο περιβαλλοντικό αντίκτυπο απ' ό,τι οι ολοκαίνουργιες.",
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '2NAFiChV7FXsNGqq7H0ae0',
            title: 'Ένα λεπτό παρακαλώ… τι άλλο πουλάτε παιδιά;',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Μπορείς να βοηθήσεις τον πλανήτη με πολλούς και ποικίλους τρόπους, αλλά όσον αφορά τις συσκευές, προσφέρουμε πολύ περισσότερα από ντιζαϊνάτα smartphone. Από συστήματα gaming έως ψαλίδια μαλλιών ή ακόμα και συστήματα ενδοεπικοινωνίας για μωρά και μηχανές εσπρέσο («νεόκοποι» γονείς, σε εσάς απευθυνόμαστε), κατά πάσα πιθανότητα έχουμε ένα προϊόν και για σένα.',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
          {
            id: '2m6IofK1kJyyLzjltRhz9n',
            title:
              'Το T-800 στην ταινία Εξολοθρευτής 2: Μέρα Κρίσης (1991) ήταν τεχνικά «ανακατασκευασμένο»;',
            text: {
              nodeType: BLOCKS.DOCUMENT,
              data: {},
              content: [
                {
                  nodeType: BLOCKS.PARAGRAPH,
                  data: {},
                  content: [
                    {
                      nodeType: 'text',
                      value:
                        'Σίγουρα θα χρειαζόταν να υποβάλουμε το Skynet σε διαδικασία πιστοποίησης προτού απαντήσουμε με ένα οριστικό «ναι» ή «όχι», αλλά κάτι μας λέει πως ναι, μιλάμε για ένα σενάριο του στυλ «θα επιστρέψω».',
                      marks: [],
                      data: {},
                    },
                  ],
                },
              ],
            },
          },
        ],
        rendering: 'HTML_ONLY',
        ctaLink: {
          link: {
            href: 'https://www.backmarket.gr/el-gr/help',
          },
          label: 'Βρες τις απαντήσεις',
        },
      },
      hiddenDevices: [],
    },
  ],
  type: 'core',
  title: 'αρχική',
  meta: {
    title: 'Το Refurbished (Σούπερ) Μάρκετ',
    description:
      'Αγόρασε τα καλύτερα refurbished smartphones, laptops και άλλα στο Back Market, έως και 70% φθηνότερα! Δωρεάν παράδοση - 12 μήνες εγγύηση - ασφαλείς πληρωμές - επιστροφές 30 ημερών',
    image: {
      src: 'https://images.ctfassets.net/mmeshd7gafk1/5BDjAiB7WVkFJcE5MXLNy4/d7c812fd12f3a6b0f0cc278b9a88b019/SEO-Website-Thumbnail.png',
      width: 516,
      height: 258,
      srcMobile: '',
      widthMobile: 0,
      heightMobile: 0,
      alt: '',
    },
    alternateLinks: [
      {
        country: 'FR',
        locale: 'fr-FR',
      },
      {
        country: 'DE',
        locale: 'de-DE',
      },
      {
        country: 'BE',
        locale: 'fr-BE',
      },
      {
        country: 'ES',
        locale: 'es-ES',
      },
      {
        country: 'IT',
        locale: 'it-IT',
      },
      {
        country: 'GB',
        locale: 'en-GB',
      },
      {
        country: 'AT',
        locale: 'de-AT',
      },
      {
        country: 'NL',
        locale: 'nl-NL',
      },
      {
        country: 'US',
        locale: 'en-US',
      },
      {
        country: 'FI',
        locale: 'fi-FI',
      },
      {
        country: 'PT',
        locale: 'pt-PT',
      },
      {
        country: 'IE',
        locale: 'en-IE',
      },
      {
        country: 'GR',
        locale: 'el-GR',
      },
      {
        country: 'SK',
        locale: 'sk-SK',
      },
      {
        country: 'SE',
        locale: 'sv-SE',
      },
      {
        country: 'JP',
        locale: 'ja-JP',
      },
      {
        country: 'AU',
        locale: 'en-AU',
      },
    ],
  },
  badge: '',
  titleSeo: 'Το Refurbished (Σούπερ) Μάρκετ',
  titleBreadcrumb: 'αρχική',
  banner: {},
  textSeo: null,
  hideFromSearchEngine: false,
  parentPageInfos: null,
  pageType: 'core',
  pageName: 'home-duplicated',
}

export default HOME_FALLBACK_DATA
